import React from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ProfileSection } from "./ProfileSection";
import { RegularHoursSection } from "./RegularHoursSection";
import { SocialLinksSection } from "./SocialLinksSection";
import { LocationData } from "../../../models/LocationData";
import { AccordionButton } from "../../Common/Accordion/AccordionButton";
import { ImpersonateOnly } from "../../Common/ImpersonateOnly";

import "./styles.scss";

export interface IEditProfileFormProps {
  locationData: LocationData;
}

export const EditProfileForm: React.FunctionComponent<IEditProfileFormProps> = (
  props
) => {
  const { locationData } = props;

  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityDetailsPage.modalEditProfile.editProfileForm.${key}`);

  return (
    <div className="edit-profile-form">
      <Accordion>
        <Accordion.Item eventKey="profile-section">
          <AccordionButton accordionItemKey="profile-section">
            {t("description")}
          </AccordionButton>
          <Accordion.Body>
            <ProfileSection
              locationData={locationData}
              accordionEventKey="profile-section"
            />
          </Accordion.Body>
        </Accordion.Item>
        <ImpersonateOnly>
          <Accordion.Item eventKey="regular-hours-section">
            <AccordionButton accordionItemKey="regular-hours-section">
              {t("regular_hours")}
            </AccordionButton>
            <Accordion.Body>
              <RegularHoursSection
                locationData={locationData}
                accordionEventKey="regular-hours-section"
              />
            </Accordion.Body>
          </Accordion.Item>
        </ImpersonateOnly>
        <ImpersonateOnly>
          <Accordion.Item eventKey="social-links-section">
            <AccordionButton accordionItemKey="social-links-section">
              {t("social_links")}
            </AccordionButton>
            <Accordion.Body>
              <SocialLinksSection
                locationData={locationData}
                accordionEventKey="social-links-section"
              />
            </Accordion.Body>
          </Accordion.Item>
        </ImpersonateOnly>
      </Accordion>
    </div>
  );
};
