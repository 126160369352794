import React from "react";

import { HookFormSocialLinksIndividual } from "./Individual";
import { SocialPlatforms } from "./types";

import "./styles.scss";

export const HookFormSocialLinks = () => {
  return (
    <div className="hook-form-social-links">
      {SocialPlatforms.map((platform) => (
        <HookFormSocialLinksIndividual key={platform} platform={platform} />
      ))}
    </div>
  );
};
