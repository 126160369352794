import React from "react";

import "./styles.scss";

interface IHookFormErrorProps {
  error?: string | undefined;
}

export const HookFormError = ({ error }: IHookFormErrorProps) => {
  return (
    <div className="hook-form-error">
      {error && (
        <>
          <div className="error-icon"></div>
          <span className="error-message">{error}</span>
        </>
      )}
    </div>
  );
};
