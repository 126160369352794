import { z } from "zod";

import i18n from "../../../../i18n";

const t = (key: string) =>
  i18n.t(`facilityDetailsPage.modalEditProfile.editProfileForm.errors.${key}`);

const timeOfDaySchema = z.object({
  hours: z.coerce.number().min(0).max(23),
  minutes: z.coerce.number().min(0).max(59)
});

// Open Time must be before Close Time
// Open Day and Close Day should be the same for the purposes of this form
const timePeriodSchema = z
  .object({
    openTime: timeOfDaySchema,
    closeTime: timeOfDaySchema
  })
  .superRefine((data, ctx) => {
    if (data.openTime.hours < data.closeTime.hours) {
      return;
    }
    // Case where open time Hours is GT close time Hours
    if (data.openTime.hours > data.closeTime.hours) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t("regularHours.periods.openAfterClose"),
        path: ["closeTime"]
      });
      return;
    }
    // Case where Hours is the same but Minutes is GTE close time
    if (data.openTime.minutes >= data.closeTime.minutes) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: t("regularHours.periods.openAfterClose"),
        path: ["closeTime"]
      });
      return;
    }
  });

// If isOpen is true, timePeriods must be present and validated
// If isOpen is false, timePeriods will not be validated
const dailyOperationHoursSchema = z
  .object({
    isOpen: z.literal(true),
    timePeriods: z.array(timePeriodSchema)
  })
  .or(
    z.object({
      isOpen: z.literal(false),
      timePeriods: z.array(z.any())
    })
  );

export const schema = z.object({
  MONDAY: dailyOperationHoursSchema,
  TUESDAY: dailyOperationHoursSchema,
  WEDNESDAY: dailyOperationHoursSchema,
  THURSDAY: dailyOperationHoursSchema,
  FRIDAY: dailyOperationHoursSchema,
  SATURDAY: dailyOperationHoursSchema,
  SUNDAY: dailyOperationHoursSchema
});

export type Schema = z.infer<typeof schema>;
export type TimeOfDay = z.infer<typeof timeOfDaySchema>;
