import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Input } from "../../Input";
import { SocialLinks, SocialPlatforms } from "../types";

import "./styles.scss";

export interface IHookFormSocialLinksIndividualProps {
  platform: (typeof SocialPlatforms)[number];
}

export const HookFormSocialLinksIndividual = ({
  platform,
}: IHookFormSocialLinksIndividualProps) => {
  const { watch, setValue } = useFormContext<SocialLinks>();

  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`socialMediaPlatforms.${key}`);

  const isSelected = watch(platform)?.url != null;

  const select = () => {
    setValue(platform, { url: "" }, { shouldDirty: true });
  };

  const unselect = () => {
    setValue(platform, { url: null }, { shouldDirty: true });
  };

  return (
    <div className="hook-form-social-links-individual">
      <label htmlFor={platform}>{t(platform)}</label>
      {isSelected ? (
        <>
          <button
            className="social-links-button unregister btn"
            type="button"
            onClick={unselect}
          ></button>
          <Input id={platform} name={`${platform}.url`} type="url" />
        </>
      ) : (
        <button
          className="social-links-button register btn"
          type="button"
          onClick={select}
        ></button>
      )}
    </div>
  );
};
