import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import {
  NavLink,
  RouteComponentProps,
  withRouter,
  useHistory,
} from "react-router-dom";

import { ExtendedReviewSummary } from "../../components/FacilityReviewSummariesComponents/ExtendedReviewSummary";
import { ReviewSummarySelector } from "../../components/FacilityReviewSummariesComponents/ReviewSummarySelector";
import { useDateTranslation } from "../../hooks/dateTranslation";
import { ImpersonateStatus, useImpersonate } from "../../hooks/impersonate";
import { useMe } from "../../hooks/queries";
import { LocationReviewSummary } from "../../models/LocationReviewSummary";
import DataSvc from "../../services/dataSvc";

import "./styles.scss";

interface MatchParams {
  locationId?: string;
  organizationId?: string;
  reviewSummaryId?: string;
}

type IFacilityReviewSummariesPageProps = RouteComponentProps<MatchParams>;

const FacilityReviewSummariesPage: React.FunctionComponent<
  IFacilityReviewSummariesPageProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string, options?: any) =>
    _t(`facilityReviewSummariesPage.${key}`, options);

  const { impersonateStatus } = useImpersonate();

  const { translateDateRange } = useDateTranslation();

  const { data: me } = useMe();
  const pathHistory = useHistory();
  const locationId = props.match?.params?.locationId ?? "";
  const defaultReviewSummaryId = props.match?.params?.reviewSummaryId ?? "";

  const [reviewSummaryId, setReviewSummaryId] = React.useState<
    bigint | undefined
  >(BigInt(defaultReviewSummaryId) || undefined);

  const { data: locationData } = useQuery(
    ["location", locationId],
    async () => {
      return await DataSvc.getLocation(locationId);
    }
  );

  const { data: history, isFetched: isFetchedHistory } = useQuery<
    LocationReviewSummary[]
  >(["location-review-summary-history", locationId, impersonateStatus], () =>
    DataSvc.getLocationReviewSummaryHistory(
      Number(locationId),
      impersonateStatus === ImpersonateStatus.IMPERSONATE
        ? {}
        : {
            publicly_visible: true,
          }
    )
  );

  useEffect(() => {
    if (impersonateStatus === ImpersonateStatus.NOT_IMPERSONATE) {
      pathHistory.push("/");
    }
  }, [impersonateStatus, pathHistory]);

  useEffect(() => {
    if (history && history.length > 0 && !reviewSummaryId) {
      setReviewSummaryId(history?.[0]?.id);
    }
  }, [history, reviewSummaryId]);

  const onHandleSelectId = (id: bigint | undefined) => {
    setReviewSummaryId(id);
    pathHistory.push(
      `/location/${locationId}${
        me?.organization_id ? `/organization/${me?.organization_id}` : ""
      }/review-summaries/${id}`
    );
  };

  const { data: reviewSummary, isFetched: isFetchedReviewSummary } =
    useQuery<LocationReviewSummary>(
      [
        "location-review-summary",
        locationId,
        reviewSummaryId?.toString(),
        impersonateStatus,
      ],
      () =>
        DataSvc.getLocationReviewSummary(
          Number(locationId),
          Number(reviewSummaryId),
          impersonateStatus === ImpersonateStatus.IMPERSONATE
            ? {}
            : {
                publicly_visible: true,
              }
        ),
      { enabled: isFetchedHistory }
    );

  const gbpLocIdNum =
    locationData?.gbp_location_id.replace("locations/", "") || undefined;

  const getNavBackUrl = () => {
    if (impersonateStatus === ImpersonateStatus.IMPERSONATE) {
      return "/admin/customizedReviewsAnalysisPage";
    } else {
      return `/location/${locationId}${
        me?.organization_id ? `/organization/${me?.organization_id}` : ""
      }`;
    }
  };

  return (
    <div className="right-content facility-review-summaries">
      <div className="back-top-bar flex-grid">
        <NavLink to={getNavBackUrl()} className="back-link">
          <i className="icons icon-back"></i>
          {impersonateStatus === ImpersonateStatus.IMPERSONATE
            ? t("back_to_admin_review_summaries")
            : t("back_to_facility_details")}
        </NavLink>
        <div className="right-btns">
          {!!gbpLocIdNum && (
            <div className="to-gbp-button">
              <a
                href={`https://business.google.com/n/${gbpLocIdNum}/profile`}
                target="_blank"
                rel="noreferrer"
                className="btn btn-blue"
                onClick={() => {
                  ReactGA.event({
                    category: "GBP Link",
                    action: "Click to GBP",
                    label: "main",
                  });
                }}
              >
                {t("to_facilitys_gbp_profile")}
              </a>
              <small className="footnote">{t("may_require_gbp_login")}</small>
            </div>
          )}
        </div>
      </div>
      {!!locationData && (
        <div className="big-bold-title">{locationData.name}</div>
      )}
      <div className="sub-title">
        <span>{t("review_summaries")}</span>
        {reviewSummary && (
          <span>
            {translateDateRange(
              // Cast to Date because the data is serialized as a string
              new Date(reviewSummary?.start_date),
              new Date(reviewSummary?.end_date)
            )}
          </span>
        )}
      </div>
      {!!history && history.length > 0 && (
        <ReviewSummarySelector
          history={history}
          selectedId={reviewSummaryId}
          onSelectId={onHandleSelectId}
        />
      )}
      {!!reviewSummary && (
        <ExtendedReviewSummary
          locationTitle={locationData?.name}
          reviewSummary={reviewSummary}
        />
      )}
      {!reviewSummary && isFetchedReviewSummary && (
        <div>{t("no_review_summaries")}</div>
      )}
    </div>
  );
};

export default withRouter(FacilityReviewSummariesPage);
