import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { LocationReviewSummary } from "../../../models/LocationReviewSummary";
import Pagination from "../../Pagination";
import StatusItem from "../StatusItem";
import "./styles.scss";

interface ICustomizedReviewsListProps {
  reviewSummariesData: LocationReviewSummary[];
  filterFormData: any;
  totalCount: number;
  onChangeFilterFormData: (filterFormData: any) => void;
  onDeleteReviewSummary: (id: number) => void;
}

const CustomizedReviewsList: React.FC<ICustomizedReviewsListProps> = ({
  reviewSummariesData,
  filterFormData,
  totalCount,
  onChangeFilterFormData,
  onDeleteReviewSummary,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`customizedReviewsAnalysisPage.customized_review_ist.${key}`);

  return (
    <>
      <div className="white-border-panel members-list">
        <div className="top-bar flex-grid" />

        <div className="table-data table-data-grid table-data-grid table-data-grid-pc-only member-table">
          <div className="row-th">
            <div className="col-th name">
              <div className="spacing">{t("title")}</div>
            </div>
            <div className="col-th role">
              <div className="spacing">{t("Status")}</div>
            </div>
            <div className="col-th email">
              <div className="spacing">{t("condition")}</div>
            </div>
            <div className="col-th email">
              <div className="spacing">{t("language")}</div>
            </div>
            <div className="col-th created-date">
              <div className="spacing">{t("period")}</div>
            </div>
            <div className="col-th">
              <div className="spacing">{t("created_at")}</div>
            </div>
            <div className="col-th">
              <div className="spacing">{t("reviews")}</div>
            </div>
            <div className="col-th">
              <div className="spacing"></div>
            </div>
          </div>

          {reviewSummariesData.map(
            (item: LocationReviewSummary, index: number) => (
              <div className="row-td" key={index}>
                <div className="col-td">
                  <NavLink
                    to={`/location/${item.location_id}/review-summaries/${item.id}`}
                    className="blue-link"
                  >
                    <div>{item.locations?.name}</div>
                    {`(${dayjs(item.start_date).format("YYYY-MM-DD")} - ${dayjs(
                      item.end_date
                    ).format("YYYY-MM-DD")})`}
                  </NavLink>
                </div>
                <div className="col-td">
                  <StatusItem status={item.status} />
                </div>
                <div className="col-td">
                  <div>Locations</div>
                  {item.locations?.name}
                </div>
                <div className="col-td">
                  <div>
                    {item.processed_reviews.length > 0
                      ? Array.from(
                          new Set(
                            item.processed_reviews.map(
                              (review) => review.processed_review.language || ""
                            )
                          )
                        ).join(", ")
                      : ""}
                  </div>
                </div>
                <div className="col-td period">
                  <div>{dayjs(item.start_date).format("YYYY-MM-DD")}</div>
                  <div className="period-tag ">|</div>
                  <div>{dayjs(item.end_date).format("YYYY-MM-DD")}</div>
                </div>
                <div className="col-td">
                  {dayjs(item.created_at).format("YYYY-MM-DD")}
                </div>
                <div className="col-td ">{item.processed_reviews.length}</div>
                <div className="col-td">
                  <button
                    className="btn btn-blue"
                    onClick={() => onDeleteReviewSummary(Number(item.id))}
                  >
                    {t("delete")}
                  </button>
                </div>
              </div>
            )
          )}
        </div>

        <Pagination
          filterFormData={filterFormData}
          totalRowsNumber={totalCount}
          onChangeFilterFormData={(filterFormData: any) => {
            onChangeFilterFormData(filterFormData);
          }}
        />
      </div>
    </>
  );
};

export default CustomizedReviewsList;
