import { Schema, TimeOfDay } from "./schema";
import { DayOfWeek, DaysOfWeek } from "../../../../models/DayOfWeek";
import { LocationData } from "../../../../models/LocationData";

export const generateDefaultValues = (data: LocationData): Schema => {
  return convertReadRegularHoursToWrite(data.regular_hours);
};

/**
 * The front end form expects a different format than what is stored in the database. (The backend format is difficult to process with a form).
 * Converts the database format to the front end format.
 */
const convertReadRegularHoursToWrite = (data?: string): Schema => {
  const periods: ApiTimePeriod[] = JSON.parse(data || "{}").periods?.map(
    (period: any) => {
      return {
        ...period,
        openTime: {
          ...period.openTime,
          minutes: period.openTime?.minutes ?? 0,
          hours: period.openTime?.hours ?? 0
        },
        closeTime: {
          ...period.closeTime,
          minutes: period.closeTime?.minutes ?? 0,
          hours: period.closeTime?.hours ?? 0
        }
      };
    }
  );
  const regularHours = DaysOfWeek.reduce((accumulator, day) => {
    const isOpen = periods?.some((p) => p.openDay === day);
    const timePeriods = periods?.filter((p) => p.openDay === day);
    if (timePeriods?.length === 0) {
      timePeriods.push({
        openDay: day,
        closeDay: day,
        openTime: { hours: 0, minutes: 0 },
        closeTime: { hours: 0, minutes: 0 }
      });
    }
    accumulator[day] = {
      isOpen,
      timePeriods
    };
    return accumulator;
  }, {} as Record<DayOfWeek, { isOpen: boolean; timePeriods: ApiTimePeriod[] }>);

  return { ...regularHours };
};

type ApiTimePeriod = {
  openDay: DayOfWeek;
  closeDay: DayOfWeek;
  openTime: TimeOfDay;
  closeTime: TimeOfDay;
};

type ApiRegularHours = {
  periods: ApiTimePeriod[];
};

/**
 * Converts the front end format back into the database format.
 */
export const convertWriteRegularHoursToRead = (
  data: Schema
): ApiRegularHours => {
  const periods: ApiTimePeriod[] = [];

  DaysOfWeek.forEach((day) => {
    if (data[day].isOpen) {
      data[day].timePeriods.forEach((period) => {
        periods.push({
          ...period,
          openDay: day,
          closeDay: day
        });
      });
    }
  });

  return { periods };
};
