import React from "react";
import { useFormContext } from "react-hook-form";

import "./styles.scss";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

export const Input = (props: InputProps) => {
  const { register } = useFormContext();

  return (
    <input className="hook-form-input" {...props} {...register(props.name)} />
  );
};
